<template>
  <div
    v-if="item"
    class="text-center sm:border-l border-b flex relative sm:px-4 sm:flex-col justify-center gap-1"
  >
    <div
      v-if="hasPricing"
      class="text-center capitalize tracking-widest text-sm flex flex-col justify-around"
    >
      <!--// removed this to manually show price: && item.price > 0-->
      <span class="px-4 xs:whitespace-no-wrap sm:p-0">
        <div
          v-if="touch_portal_installation && !branded_mode_user"
          class="flex items-center cursor-pointer"
          @click="isCostsHidden = !isCostsHidden"
        >
          <div class="relative">
            <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
            <div class="switch-toggle" :class="{ _active: !isCostsHidden }"></div>
          </div>
          <div class="ml-2 text-gray-700 font-medium whitespace-no-wrap">
            <span v-text="isCostsHidden ? 'Costs Off' : 'Costs On'"></span>
          </div>
        </div>
        <div v-show="!isCostsHidden || touch_business_installation || branded_mode_user">
          <span v-if="touch_business_installation" class="hidden xs:inline">Item Price: </span>
          <span v-else class="hidden xs:inline">Item Cost: </span>
          <strong v-html="formatted_item_price"> </strong>
          <span class="whitespace-no-wrap text-xs xs:text-sm block xs:inline relative">
            <template v-if="showTax"> inc</template><template v-else> exc</template> vat
            <price-breakdown-button v-if="hasPriceBreakdown"></price-breakdown-button>
            <info-popup
              :id="'designer_header:price_breakdown'"
              class="absolute right-0 top-0 -m-6"
              :info_message="'Once you have added your pricing you can then use the price breakdown calculator within the sales and survey designer by clicking the magnifying glass, as pictured in the test your pricing example.'"
              :next_id="'designer:switch_view'"
            ></info-popup>
          </span>
        </div>
      </span>
    </div>
    <div v-if="!hasPricing && !branded_mode_user" class="flex flex-col justify-around text-xs px-2">
      <router-link to="/setup/prices">Click here to setup pricing</router-link>
    </div>
    <div v-if="sandboxIsActive || ($store.state.auth.superuser && !branded_mode_user)" class="text-center hidden xs:flex gap-2 justify-around p-2 pr-4 sm:p-0">
      <button class="btn-action md:py-3 md:px-10 whitespace-no-wrap" @click="sandboxReport">
        Financial Summary
      </button>
      <button
        class="btn-action md:py-3 md:px-10 whitespace-no-wrap"
        @click="addToBasket"
        v-text="`Save To ${buttonName}`"
      ></button>
    </div>
    <div v-else class="text-center hidden xs:flex justify-around pr-4 flex-col sm:pr-0">
      <button
        class="btn-action btn-lg whitespace-no-wrap"
        @click="addToBasket"
        v-text="`Save To ${buttonName}`"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import currencyHelper from '@/mixins/currencyHelper';
import PriceBreakdownButton from '@/components/shared/nav/PriceBreakdownButton.vue';

export default {
  components: {
    'price-breakdown-button': PriceBreakdownButton,
  },
  mixins: [currencyHelper],
  props: ['on_designer_page'],
  data() {
    return {
      isCostsHidden: true,
    };
  },
  computed: {
    ...mapState('basket', {
      buttonName: 'buttonName',
    }),
    hasPriceBreakdown() {
      return (
        (this.touch_business_installation &&
          this.$store.state.basket.salesSectorType !== this.enums.salesSectorType.RETAIL) ||
        (!this.branded_mode_user && this.$store.getters['user/has_price_breakdown_enabled'])
      );
    },
    tableOfDefaults() {
      const prefix = '<ul class="pt-3">';
      const suffix = '</ul>';

      const rows = this.item.specification
        .filter((spec) => spec.mustBeSelected && !spec.hasBeenSelected)
        .map((spec) => `<li>${spec.name}</li>`)
        .join('');

      return `${prefix}${rows}${suffix}`;
    },
    requiresInput() {
      return this.item.specification.some((spec) => spec.mustBeSelected && !spec.hasBeenSelected);
    },
    requiresMeasurements() {
      const needsDimensionInput = this.item.dimensions.some(
        (dimension) => dimension.requiresInput && !dimension.hasBeenInput,
      );

      return this.branded_mode_user
        ? this.contractHasSizing && needsDimensionInput
        : needsDimensionInput;
    },
    hasPricing() {
      return !this.branded_mode_user || this.$store.getters['user/hasBrandedPricingOn'];
    },
    basketLink() {
      if (this.touch_business_installation) {
        return `/customer/${this.$store.state.basket.customerId}/sales-dashboard/basket`;
      }

      return this.branded_mode_user ? this.branded_url : '/basket';
    },
    ...mapGetters({
      item: 'basket/currentItem',
      basketCurrency: 'basket/getCurrency',
      branded_url: 'branded/basketURL',
      contractHasSizing: 'basket/contractHasSizing',
      sandboxIsActive: 'sandbox/sandboxIsActive',
    }),
    formatted_item_price() {
      return this.formatCurrency(this.basketCurrency, this.item_price);
    },
    item_price() {
      return (this.item.price + this.getTax()) / this.item.quantity;
    },
    showTax() {
      return (
        this.branded_mode_user ||
        this.$store.state.basket.salesSectorType === this.enums.salesSectorType.RETAIL
      );
    },
  },
  mounted() {
    if (this.$route.query.isInvalidSpec) {
      this.isInvalidSpec();
      this.$router.replace({ query: null });
    }
  },
  methods: {
    getTax() {
      if (this.showTax) {
        let tax = this.item.tax
        for (let i = 0; i < this.item.extras.length; i++) {
          if (this.item.extras[i].priceIncTax && this.item.extras[i].price) {
            tax += this.item.extras[i].priceIncTax - this.item.extras[i].price
          }
        }
        return tax
      } else {
        return 0
      }
    },
    sandboxReport() {
      this.$store.dispatch('basket/getSandboxReportUrlForCurrentItem').then((url) => {
        window.open(url, '_blank').focus();
      });
    },
    addToBasket() {
      this.dimensionBoxesHighlight(false);

      if (this.branded_mode_user) {
        this.routerPush(this.basketLink);
        return;
      }

      if (!this.requiresMeasurements && !this.requiresInput) {
        this.routerPush(this.basketLink);
        return;
      }

      if (this.requiresInput) {
        this.isInvalidSpec();
        this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'specification');
        return;
      }

      if (this.requiresMeasurements) {
        this.alertBox().fire('You must supply dimensions before saving');
        this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'sizes');
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    isInvalidSpec() {
      this.alertBox()
        .fire({
          title: 'You have not made required selections',
          html: `<p>Please review and answer the following questions</p> ${this.tableOfDefaults}`,
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          confirmButtonText: 'Accept Defaults',
          cancelButtonText: 'Back',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('basket/processingForceDefaults').then(() => {
              this.routerPush(this.basketLink);
            });
          }
        });
    },
    dimensionBoxesHighlight(highlight) {
      const inputBoxes = document.querySelectorAll('.input-wrapper');
      inputBoxes.forEach((inputBox) => {
        if (highlight) {
          inputBox.classList.add('highlighted');
        } else {
          inputBox.classList.remove('highlighted');
        }
      });
    },
  },
};
</script>
