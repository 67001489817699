<template>
  <div :class="{ 'overflow-hidden flex flex-col flex-grow justify-around text-center': loading }">
    <div v-show="loading">
      <div v-if="!isDelay" :class="textSize">
        {{ label }} 
        <span class="dot text-4xl leading-none">.</span><span class="dot text-4xl leading-none">.</span><span class="dot text-4xl leading-none">.</span>
      </div>
    </div>
    <div v-show="!loading">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Loading',
    },
    textSize: {
      type: String,
      default: 'text-xl',
    },
    delayTime: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      isDelay: true
    }
  },
  created() {
    setTimeout(() => {
      this.isDelay = false
    }, this.delayTime);
  }
};
</script>

<style scoped>
.dot {
  animation: dot linear 0.9s infinite;
}

.dot:nth-of-type(2) {
  animation-delay: 0.3s;
}

.dot:nth-of-type(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}
</style>
