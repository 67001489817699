<template>
  <div>
    <Update
      v-if="editing"
      :organisation="organisation"
      :modal_open="editing"
      @close="(editing = false), $emit('getOrganisation')"
    />
    <div class="flex flex-wrap bg-white border m-3 xs:m-10 mt-4 relative pr-16">
      <div class="p-3 w-full xs:w-1/2 sm:w-1/3 md:w-1/4 flex flex-col gap-2">
        <div class="font-bold">Disable Portal Processing</div>
        <div class="flex flex-col justify-around">{{ organisation.portalOnStop ? 'On' : 'Off' }}</div>
      </div>
      <div class="absolute bottom-0 right-0 p-5">
        <div
          class="px-2 py-1 text-2xs rounded text-green-600 border border-green-600 cursor-pointer hover:text-white hover:bg-green-600"
          @click="editing = true"
        >
          <i class="fa fa-pencil"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Update from '@/components/business/manageTouchAccount/accountSettings/portalProcessing/Update.vue';

export default {
  components: {
    Update,
  },
  props: {
    organisation: Object
  },
  data() {
    return {
      editing: false
    };
  },
};
</script>
