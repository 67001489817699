<template>
  <div class="bg-gray-200 w-full h-screen overflow-y-auto">
    <CancelOrderModal
      v-if="cancelModalOpen"
      @cancel="cancelOrder()"
      @close="cancelModalOpen = false"
    />
    <portal v-if="edit_consumer_open" to="portal_popup">
      <consumer-popup
        v-show="edit_consumer_open"
        :contract="order"
        @close="edit_consumer_open = false"
        @saved="savedConsumer()"
      ></consumer-popup>
    </portal>
    <loading :loading="loading" class="scrolling-touch p-3 md:p-10 h-full">
      <div
        v-if="order.locked"
        key="locked-banner"
        class="flex bg-red-200 py-5 px-10 rounded-lg mb-6 xs:mb-10"
        data-index="1"
      >
        <div class="mr-6 hidden xs:flex flex-col justify-around">
          <i class="fal fa-lock-alt"></i>
        </div>
        <div class="flex-grow flex flex-col xs:flex-row justify-between text-center xs:text-left">
          <div v-if="lockedByUser.id == user.id" class="flex flex-col justify-around">
            <slot name="locked_by_user"> This order is locked </slot>
          </div>
          <div v-else class="flex flex-col justify-around">
            This order is locked by {{ lockedByUser.firstName }} {{ lockedByUser.lastName }} -
            {{ lockedByUser.email }}
          </div>
          <transition v-if="lockedByUser.id == user.id || isAdmin" type="out-in" name="fade-in">
            <template v-if="order_unlocking">
              <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-action">
                <i class="fal fa-unlock mr-2"></i> Unlocking Order
              </a>
            </template>
            <template v-else>
              <a class="w-full mt-3 xs:w-auto xs:mt-0 btn-danger" @click="unlockOrder()">
                <i class="fal fa-unlock-alt mr-2"></i> Unlock Order
              </a>
            </template>
          </transition>
        </div>
      </div>
      <slot name="consumer_details"></slot>
      <div v-if="order.jobs" key="action-btns" class="flex flex-col xs:flex-row gap-3 flex-wrap my-3">
        <slot name="download_survey"></slot>
        <slot name="download_confirmation"></slot>
        <slot name="download_glass_order"></slot>
        <div v-if="orderIsPending" class="flex-1">
          <a
            class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
            @click.prevent="placeOrder()"
          >
            <span>Confirm Customer Order</span>
            <i class="fa fa-shopping-basket text-sm"></i>
          </a>
        </div>
        <div v-if="orderIsPending" class="flex-1">
          <a
            class="btn-action whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
            @click.prevent="$emit('isEditJob', () => editJob())"
          >
            <span>Edit Products</span>
            <i class="fa fa-pen-to-square text-sm"></i>
          </a>
        </div>
        <div v-if="showReleaseToPortalOrAdminButton" class="flex-1">
          <slot name="release_to_portal"></slot>
          <slot name="release_to_admin"></slot>
        </div>
        <div v-if="cancellationAvailable" class="flex-1">
          <a
            class="btn-danger whitespace-no-wrap font-medium flex justify-between w-full p-3 px-6"
            @click.prevent="cancelModalOpen = true"
          >
            <span>Cancel Order</span>
            <i class="fa fa-trash-can text-sm"></i>
          </a>
        </div>
      </div>
      <div class="flex flex-col xs:flex-row items-center gap-3">
        <slot name="surveyor"></slot>
        <slot name="fitter"></slot>
        <slot name="sales-person"></slot>
      </div>
      <div key="order-details" data-index="0" class="flex flex-col xs:flex-row pt-3 gap-3">
        <div class="flex flex-col flex-1">
          <div class="bg-white border p-3 h-full">
            <div class="border-b font-bold mb-3 pb-3">Order Details</div>
            <table class="">
              <tbody>
                <tr class="xs:hidden">
                  <td class="pb-1 w-32">Reference:</td>
                  <td class="pb-1">
                    {{ order.reference }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-1 w-32">Customer PO:</td>
                  <td class="pb-1">
                    {{ order.customerPONumber }}
                  </td>
                </tr>
                <tr>
                  <td class="pb-1 w-32">Created at:</td>
                  <td class="pb-1">
                    {{ formatDate(order.date) }}
                  </td>
                </tr>
                <tr v-if="delivery">
                  <td class="pb-1 w-32">Delivery Address:</td>
                  <td class="pb-1" v-html="deliveryAddress"></td>
                </tr>
                <tr v-if="delivery">
                  <td class="pb-1 w-32">Delivery Date:</td>
                  <td class="pb-1">
                    {{ formatDate(delivery.requestedDate) }}
                  </td>
                </tr>
                <tr v-if="delivery && delivery.notes">
                  <td class="pb-1 w-32">Delivery Notes:</td>
                  <td class="pb-1" v-text="delivery.notes"></td>
                </tr>
              </tbody>
            </table>
            <div v-if="order.jobs && order.jobs[0]?.issuePresentAccepted" class="flex items-center gap-3 bg-green-500 text-white px-3 py-2 rounded mt-5">
              <span>Issues Acknowledged</span>
              <i class="ml-auto text-lg fal fa-check-circle"></i>
            </div>
          </div>
        </div>
        <div v-if="order.jobs" class="flex-1 flex flex-col">
          <div class="bg-white border p-3 h-full">
            <div class="border-b font-bold mb-3 pb-3">
              <div v-if="job?.discountApplied" class="flex items-center gap-2">
                <div>Discounted {{ breakdown }}</div>
                <Tooltip :width="200">
                  <span class="font-thin">
                    The discount code 
                    <span class="text-green-500">{{ job?.discountCode }}</span> has been applied
                    <span v-if="job?.items?.some(x => x.override === true)"> to an item in this quote.</span>
                    <span v-else> to the subtotal of this quote.</span>
                  </span>
                </Tooltip>
              </div>
              <div v-else-if="job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="flex items-center gap-2">
                <div>Manual {{ breakdown }}</div>
                <Tooltip :width="200">
                  <span v-if="job?.overridePrice === true" class="font-thin">The subtotal has been manually updated</span>
                  <span v-else class="font-thin">The price of an item has been manually updated.</span>
                </Tooltip>
              </div>
              <div v-else>Price</div>
            </div>
            <table>
              <tbody>
                <tr class="align-baseline">
                  <td class="pb-1 w-32">Subtotal:</td>
                  <td>
                    <span v-if="order.jobs[0].calculatedPriceTaxable">
                      <span v-if="job?.discountApplied || job?.overridePrice === true || job?.items?.some(x => x.override === true)" class="line-through opacity-50">
                        {{ formatCurrency(order.jobs[0].customerCurrency, order.jobs[0].calculatedPriceTaxable) }}
                      </span>
                    </span>
                    <span class="flex items-center gap-2">
                      <span
                        class="text-brand-primary"
                        v-html="formatCurrency(order.jobs[0].customerCurrency, order.jobs[0].priceBeforeTax)"
                      ></span>
                      <PriceBreakdown
                        type="Subtotal"
                        :currency="touchJob.currency()"
                        :product-cost="touchJob.productCost()"
                        :delivery-cost="touchJob.deliveryCost()"
                        :fitting-price="touchJob.fittingPrice()"
                        :survey-price="touchJob.surveyPrice()"
                        :extras-supply-fit-price="touchJob.job.extrasSupplyFitPrice"
                        :extras-supply-only-price="touchJob.job.extrasSupplyOnlyPrice"
                      />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="pb-1 w-32">VAT:</td>
                  <td
                    class="pb-1 text-brand-primary"
                    v-html="formatCurrency(order.jobs[0].customerCurrency, order.jobs[0].tax)"
                  ></td>
                </tr>
                <tr>
                  <td class="w-32">Total:</td>
                  <td>
                    <span class="flex items-center gap-2">
                      <span
                        class="text-brand-primary"
                        v-html="formatCurrency(order.jobs[0].customerCurrency, order.jobs[0].priceIncludingTax)"
                      ></span>
                      <PriceBreakdown
                        type="Total"
                        :currency="touchJob.currency()"
                        :product-cost="touchJob.productCostIncTax()"
                        :delivery-cost="touchJob.job.priceDeliveryChargeIncTax"
                        :fitting-price="touchJob.job.fittingPriceIncTax"
                        :survey-price="touchJob.job.surveyPriceIncTax"
                        :extras-supply-fit-price="touchJob.job.extrasSupplyFitPriceIncTax"
                        :extras-supply-only-price="touchJob.job.extrasSupplyOnlyPriceIncTax"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <slot name="requote"></slot>
      </div>
      <div v-if="order.jobs" key="notes" data-index="0" class="flex flex-col xs:grid grid-cols-3 xs:flex-row gap-3 py-3">
        <slot
          name="customer_notes"
          :is-read-only-note="isReadOnlyNote"
          :contract-id="order.id"
          :job-id="order.jobs[0].jobKey"
          :note-type="enums.noteType.CUSTOMER"
          :note="notes.find((x) => x.noteType === enums.noteType.CUSTOMER)"
        >
        </slot>
        <slot
          name="internal_notes"
          :is-read-only-note="isReadOnlyNote"
          :contract-id="order.id"
          :job-id="order.jobs[0].jobKey"
          :note-type="enums.noteType.INTERNAL"
          :note="notes.find((x) => x.noteType === enums.noteType.INTERNAL)"
        >
        </slot>
        <div class="flex flex-col">
          <div class="bg-white border p-3 h-full">
            <show-note
              :read-only="isReadOnlyNote"
              :contract-id="order.id"
              :job-id="order.jobs[0].jobKey"
              :note-type="enums.noteType.SUPPLIER"
              :note="notes.find((x) => x.noteType === enums.noteType.SUPPLIER)"
              @loadNotes="loadNotes"
            >
              Supplier Notes
            </show-note>
          </div>
        </div>
      </div>
      <hr key="divider" class="-mx-3 md:-mx-10" />
      <div class="flex justify-between items-center my-6">
        <div class="text-2xl font-medium">Products</div>
      </div>
      <div v-if="order.jobs" class="pb-5">
        <contract-job-line-item
          v-for="(line_item, index) in order.jobs[0].items"
          :key="index"
          :show-price="true"
          :data-index="index"
          :item="line_item"
          :item-number="index + 1"
          :job="touchJob"
          :total-items="order.jobs[0].items.length"
          :breakdown="breakdown"
        >
        </contract-job-line-item>
      </div>
      <slot name="download_history"></slot>
    </loading>
  </div>
</template>

<script>
import ConsumerPopup from '@/components/shared/ConsumerPopup.vue';
import jobHelper from '@/mixins/jobHelper';
import { mapActions, mapGetters } from 'vuex';
import dateHelper from '@/mixins/dateHelper';
import currencyHelper from '@/mixins/currencyHelper';
import LineItem from '@/components/shared/job/LineItem.vue';
import ShowNote from '@/components/shared/ShowNote.vue';
import CancelOrderModal from '@/components/shared/CancelOrderModal.vue';
import Tooltip from '@/components/shared/Tooltip.vue';
import PriceBreakdown from '@/components/shared/job/PriceBreakdown.vue';

export default {
  components: {
    'consumer-popup': ConsumerPopup,
    'contract-job-line-item': LineItem,
    ShowNote,
    CancelOrderModal,
    Tooltip,
    PriceBreakdown
  },
  mixins: [dateHelper, currencyHelper, jobHelper],
  props: {
    customer: Object,
    showReleaseToPortalOrAdminButton: Boolean,
    cancellationAvailable: Boolean,
    breakdown: String,
  },
  data() {
    return {
      edit_consumer_open: false,
      order: [],
      delivery: {},
      line_items: [],
      loading: true,
      togglingVisibility: false,
      cancelModalOpen: false,
      orderAutoUpdateTimeoutEnded: false,
      isReadOnlyNote: false,
      notes: [],
      lockedByUser: false,
      isAdmin: true,
      order_unlocking: false,
      orderPaymentStatus: undefined
    };
  },
  computed: {
    ...mapGetters({
      isCreateQuoteEnabled: 'feature/getIsCreateQuoteEnabled',
      isCreateOrderEnabled: 'feature/getIsCreateOrderEnabled',
      isViewOrdersEnabled: 'feature/getIsViewOrdersEnabled',
      isViewQuotesEnabled: 'feature/getIsViewQuotesEnabled',
      user: 'auth/user',
    }),
    statusText() {
      if (
        [this.enums.PaymentStatus.AWAITING, this.enums.PaymentStatus.FAILED].includes(
          this.orderPaymentStatus,
        ) &&
        this.order.finalised
      ) {
        return 'Placed (Pending Payment)';
      }
      if (this.order.saveState === this.enums.orderStatusSaveStates.STARTED) {
        return 'Saving';
      }
      if (this.order.saveState === this.enums.orderStatusSaveStates.STARTED) {
        return 'Failed';
      }
      if (this.order.orderStatus === window.enum.orderStatus.CANCELLED) {
        return 'Cancelled';
      }
      return this.order.finalised ? 'Placed' : 'Pending';
    },
    orderIsPending() {
      return !this.order.finalised;
    },
    job() {
      return this.order.jobs ? this.order.jobs[0] : undefined;
    },
    deliveryAddress() {
      try {
        const selectedAddress = this.order.jobs[0].delivery.address;

        return [
          [selectedAddress.line1, selectedAddress.line2, selectedAddress.line3]
            .filter(Boolean)
            .join(', '),
          [selectedAddress.town, selectedAddress.code, selectedAddress.iso]
            .filter(Boolean)
            .join(', '),
        ]
          .filter(Boolean)
          .join('<br>');
      } catch (error) {
        return 'N/A';
      }
    },
    order_name() {
      if (!this.order.consumer) {
        return '';
      }

      return [this.order.consumer.FirstName, this.order.consumer.LastName]
        .filter(Boolean)
        .join(' ');
    },
  },
  async mounted() {
    const customerID = this.$route.params.customerId;
    await this.loadOrder(this.$route.params.id);
    await this.loadNotes();

    if (this.$route.query.orderPaymentStatus) {
      this.orderPaymentStatus = Number(this.$route.query.orderPaymentStatus);
    } else {
      this.orderPaymentStatus = this.order.orderPaymentStatus;
    }

    if (this.order.locked) {
      await this.loadLockedByUser(this.order.lockedBy, customerID);
    }
  },
  beforeDestroy() {
    this.orderAutoUpdateTimeoutEnded = true;
  },
  methods: {
    ...mapActions({
      customerListContacts: 'user/customerListContacts',
    }),
    async unlockOrder() {
      this.order_unlocking = true;
      await this.$store.dispatch('touch/unlockQuote', {
        contractId: this.$route.params.id,
      });

      await this.loadOrder(this.$route.params.id);
      this.order_unlocking = false;
    },
    async loadLockedByUser(id, customerID) {
      const resp = await this.$store.dispatch('user/customerGet', customerID);
      const result = resp.users.filter((usr) => usr.id === id)[0];
      const staffResp = await this.customerListContacts();
      const staffResult = staffResp.filter((usr) => usr.id === id)[0];
      this.lockedByUser = result ?? staffResult;
    },
    async cancelOrder() {
      const res = await window.touch.cancelOrder(this.order.id);
      if (res.status === 400) {
        this.alertBox().fire({
          title: 'Contract Locked',
          text: 'This contract is currently locked.',
        });
      } else {
        this.$emit('redirectCancel');
      }
    },
    async toggleVisibility() {
      // we don't need to do anything if the contract is being processed.
      if (this.togglingVisibility) {
        return;
      }
      this.togglingVisibility = true;
      this.$emit('setVisibility');
      // we can now safely remove the contract from the processing list
      this.togglingVisibility = false;
    },
    async savedConsumer() {
      this.loading = true;
      this.edit_consumer_open = false;
      await this.loadOrder(this.$route.params.id);
      this.loading = false;
    },
    async loadOrder(id, noLoadPage) {
      this.loading = !noLoadPage;
      this.order = await this.$store.dispatch('touch/loadOrder', {
        customerId: this.$route.params.customerId,
        id,
      });
      this.$emit('setOrder', this.order);
      if (
        (typeof this.order.jobs === 'undefined' || this.order.jobs.length === 0) &&
        this.orderAutoUpdateTimeoutEnded === false
      ) {
        setTimeout(async () => {
          await this.loadOrder(id, true);
        }, 5000);
        return;
      }
      this.delivery = this.order.jobs[0]?.delivery;
      if (this.order.finalised) {
        this.isReadOnlyNote = true;
      }
      this.loading = false;
    },
    async editJob() {
      this.loading = true;
      this.$emit('setCustomerId', this.$route.params.customerId);
      await this.$store
        .dispatch('basket/fromOrder', {
          contractId: this.$route.params.id,
          jobKey: 1,
        })
        .then(() => {
          this.loading = false;
          this.$store.commit('basket/setReference', this.order.reference);
          this.$emit('redirectToBasket');
        })
        .catch((error) => {
          this.loading = false;
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
        });
    },
    async placeOrder() {
      if (!this.isCreateOrderEnabled) {
        this.alertBox().fire({
          title: 'Feature unavailable',
          text: 'Please contact support; Create Order Is Not Available',
        });
        return;
      }

      if (this.customer.customer.customerStatus === this.enums.customerStatus.ON_STOP) {
        this.alertBox().fire({
          title: 'Customer Status - On Stop',
          text: 'Orders cannot be placed for this account please speak to your fabricator',
        });
        return;
      }

      this.$emit('setCustomerId', this.$route.params.customerId);
      this.$store
        .dispatch('basket/fromOrder', {
          contractId: this.$route.params.id,
          jobKey: 1,
        })
        .then(() => {
          this.$store.commit('basket/setReference', this.order.reference);
          this.$emit('redirectToCheckout');
        })
        .catch((error) => {
          this.alertBox().fire({
            title: 'Error',
            text: error,
          });
        });
    },
    async showPdf() {
      this.loading = true;
      await window.touch.downloadOrderReport(this.$route.params.id, this.order.jobs[0].jobKey);
      this.loading = false;
    },
    async showSurveyPdf() {
      this.loading = true;
      await window.touch.downloadManualSurveyReport(
        this.$route.params.id,
        this.order.jobs[0].jobKey,
      );
      this.loading = false;
    },
    async showGlassOrder() {
      this.loading = true;
      await window.touch.downloadGlassOrderReport(this.$route.params.id, this.order.jobs[0].jobKey);
      this.loading = false;
    },
    async loadNotes() {
      try {
        const job = this.order.jobs[0];
        const notes = await this.$store.dispatch('touch/loadNotes', {
          contractId: this.order.id,
          jobId: job ? job.jobKey : undefined,
        });
        this.notes = notes || [];
      } catch (error) {
        this.alertBox().fire({
          title: 'Error',
          text: error,
        });
        throw new Error(error);
      }
    },
  },
};
</script>
