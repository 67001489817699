<template>
  <div class="sm:py-2">
    <div class="sm:hidden">
      <div class="border-solid border-t border-gray-300"></div>
      <div class="p-2">
        <MenuButton icon="fa-home" label="Home" url="/" :active="'home'"></MenuButton>
      </div>
      <div class="border-solid border-t border-gray-300"></div>
    </div>
    <div class="p-2">
      <MenuButton
        icon="fa-users"
        label="Customers"
        url="/customer"
        :active="'customer'"
      ></MenuButton>
      <MenuButton
        v-if="is_admin_user"
        icon="fa-sharp fa-light fa-user-helmet-safety"
        label="Staff"
        url="/manage-touch-account/staff"
        :active="'staff'"
      ></MenuButton>
      <MenuButton
        v-if="is_admin_user"
        icon="fa-sharp fa-light fa-sitemap"
        label="Product Setup"
        url="/product-setup"
        :active="'product-setup'"
      ></MenuButton>
      <MenuButton
        v-if="is_admin_user && isBranchManagerAvailable"
        key="branch"
        icon="fa-light fa-code-branch"
        :data-index="5"
        label="Branch Management"
        url="/branch"
        :active="'branch'"
      ></MenuButton>
    </div>
    <div class="border-solid border-t border-gray-300 my-2"></div>
    <div class="p-2">
      <router-link v-if="!isBranchManager && isDealerNetworkModeAvailable" to="/dealer-network">
        <div
          class="flex items-center hover:bg-gray-300"
          :class="{ 'bg-gray-300': $route.meta.active === 'dealer-network' }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-yellow-500 mr-4"></div>
          </div>
          <span>View Enquiries</span>
        </div>
      </router-link>
      <router-link v-if="!isLeadGen" :to="{ name: 'All Orders', query: { ...$route.query, type: 'order', status: '1' } }">
        <div
          class="flex items-center hover:bg-gray-300"
          :class="{
            'bg-gray-300':
              $route.meta.active === 'all-orders' &&
              $route.query.type === 'order' && $route.query.status === '1',
          }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-green-500 mr-4"></div>
          </div>
          <span>Pending Orders</span>
        </div>
      </router-link>
      <router-link v-if="!isLeadGen" :to="{ name: 'All Orders', query: { ...$route.query, type: 'order', status: '2' } }">
        <div
          class="flex items-center hover:bg-gray-300"
          :class="{
            'bg-gray-300':
              $route.meta.active === 'all-orders' &&
              $route.query.type === 'order' && $route.query.status === '2',
          }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-orange-500 mr-4"></div>
          </div>
          <span>Placed Orders</span>
        </div>
      </router-link>
      <router-link v-if="!isLeadGen" :to="{ name: 'All Orders', query: { ...$route.query, type: 'order', status: '3' } }">
        <div
          class="flex items-center hover:bg-gray-300"
          :class="{
            'bg-gray-300':
              $route.meta.active === 'all-orders' &&
              $route.query.type === 'order' && $route.query.status === '3',
          }"
        >
          <div class="p-1">
            <div class="rounded-full h-5 w-5 border-solid border-2 border-red-500 mr-4"></div>
          </div>
          <span>Cancelled Orders</span>
        </div>
      </router-link>
    </div>
    <div class="border-solid border-t border-gray-300 my-2"></div>
    <div class="p-2">
      <MenuButton
        v-if="isRackManagerEnabled && !isBranchManager"
        icon="fa-regular fa-boxes-stacked"
        label="Rack Manager"
        url="/rack-manager/map"
        :active="'rack-manager'"
      ></MenuButton>
      <MenuButton
        v-if="is_admin_user && isDealerNetworkModeAvailable && !isBranchManager && !isBnQ"
        icon="fa-light fa-chart-network"
        label="Dealer Network"
        url="/dealer-network-setup"
        :active="'dealer-network-setup'"
      >
      </MenuButton>
      <MenuButton
        icon="fa-book-open"
        label="Resource Centre"
        :url="isLinkedToBranch ? '/marketing' : '/manage-marketing'"
        :active="'marketing'"
      ></MenuButton>
      <MenuButton
        icon="fa-megaphone"
        label="News"
        :url="isLinkedToBranch ? '/news' : '/manage-news'"
        :active="'manage-news'"
      ></MenuButton>
      <MenuButton
        v-if="!isLeadGen && !isBranchManager"
        icon="fa-chart-line"
        label="KPI"
        url="/kpi"
        :active="'kpi'"
      ></MenuButton>
    </div>
    <div class="border-solid border-t border-gray-300 my-2"></div>
    <div class="p-2">
      <MenuButton
        icon="fa-store"
        label="TOUCH Store"
        url="/touch-store-admin"
        :active="'touch-store'"
      ></MenuButton>
      <MenuButton
        icon="fa-square-question"
        label="Knowledge Base"
        :external_link="true"
        url="https://bmgroupholdingslimited.freshdesk.com/support/solutions/101000252273"
        :active="''"
      ></MenuButton>
    </div>
    <router-link v-if="is_admin_user" to="/manage-touch-account/company-details" class="sm:hidden">
      <div class="text-center p-3">
        <div class="btn-action">My Account</div>
      </div>
    </router-link>
    <SupportPopUp :company-name="$store.state.auth.companyName" />
  </div>
</template>

<script>
import SupportPopUp from '@/components/shared/nav/SupportPopUp.vue';
import MenuButton from '@/components/shared/nav/MenuButton.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'BusinessMenuItems',
  components: {
    MenuButton,
    SupportPopUp,
  },
  data() {
    return {
      user: undefined,
    };
  },
  computed: {
    ...mapState('auth', {
      isDealerNetworkModeAvailable: 'isDealerNetworkModeAvailable',
      isBranchManagerAvailable: 'isBranchManagerAvailable',
      isBranchManager: 'isBranchManager',
    }),
    ...mapGetters({
      authUser: 'auth/user',
    }),
    ...mapGetters('feature', {
      isRackManagerEnabled: 'isRackManagerEnabled',
    }),
    setup_page_is_active() {
      return this.$route.meta.active === 'dealer-network-setup';
    },
    isLeadGen() {
      return (
        this.$store.state.style.fabricator?.accountProductStatus ===
        this.enums.ProductStatus.LEAD_GEN_ONLY
      );
    },
    isBnQ() {
      return this.$store.state.style.fabricator.name === 'B & Q'
    },
    isLinkedToBranch() {
      return this.$store.state.user.customer.users.some(({ email, branchCompanyId }) => branchCompanyId && this.$store.state.auth.email === email)
    },
  },
};
</script>
